$bar-container-width: 200px;

figure.tessellation {
  position: relative;
  display: inline-block;
  max-width: $bar-container-width;
  vertical-align: top;
  margin: 0px 15px 0px 5px;
}

h4.tessellation-chart-title {
  display: flex;
  align-items: center;
  text-align: center;
  height: 32px;
  line-height: 16px;
  margin: 0 0 15px;
  // overflow: hidden;

  span {
    margin: auto;
    position: relative;
    svg {
      margin-left: 5px;
      position: absolute;
      bottom: 0;
    }
    .details {
      display: none;
    }
    &:hover {
      cursor: help;

      svg {
        display: none;
      }
      .details {
        bottom: -10px;
        display: block;
        background-color: #f5f5f5;
        border: 1px solid #122649;
        border-radius: 5px;
        z-index: 1;
        width: 100%;
        font-size: 80%;
        padding: 5px;
      }
    }
  }
}

.color-cluster-signal {
  flex-direction: row !important;
  // background-color: red;
  align-items: center;
  width: 100% !important;
  border-radius: 0 !important;
  // Color Picker
  div:first-child {
    border-radius: 0 !important;
    button {
      width: 40px;
      height: 40px;
      margin: 0;
      border-radius: 4px 0 0 4px;
      border: 1px solid #cbcbcb;
      border-right: none;
      box-shadow: none;
    }
  }
  .MuiFormControl-root {
   fieldset {
     border-color: #cbcbcb;
     border-radius: 0 4px 4px 0;
    //  border-left: none
   }
  }
  
}

@media print {
  figure.tessellation {
    margin: 0cm auto;
  }
}

figure.tessellation.domain-classification {
  // override max width from figure.tessellation
  max-width: 100%;
}

@media print {
  figure.tessellation.domain-classification {
    // override max width from figure.tessellation
    max-width: 100%;
  }
}

figcaption {
  min-height: 3em;
  text-align: center;
}

svg.tessellation {
  position: relative;
  // do not set font size in stylesheet, the font is set to 2% of the viewBox in the svg itself
}

svg.tessellation rect,
svg.tessellation line {
  stroke: black;
}

svg.tessellation rect,
svg.tessellation line,
svg.tessellation polyline {
  stroke-linecap: round;
}

g:hover polyline {
  stroke-opacity: 0.3;
}

svg.tessellation polyline {
  stroke-linejoin: miter;
  stroke-miterlimit: 8;
  &:hover {
    stroke-opacity: 1;
    cursor: crosshair;
  }
}

svg.tessellation.visualization {
  display: inline-block;
  overflow: visible;
}

svg.tessellation.domain-classification.visualization {
  max-width: 100%;
}

g.tessellation.domain-classification.cutoff-group {
  line.cutoff-buffer {
    stroke-opacity: 0;
  }
  line.cutoff-line {
    stroke-dasharray: 10 5;
    stroke-opacity: 0.5;
  }
  &:hover {
    cursor: ew-resize;
    line.cutoff-line {
      stroke-opacity: 1;
    }
  }
}

line.tessellation.visualization.geologs {
  stroke: white;
  stroke-dasharray: 3 2;
  stroke-opacity: 0.8;
  &:hover {
    stroke-opacity: 1;
    cursor: crosshair;
  }
}

.variables line.tessellation.visualization.geologs {
  stroke: black;
}

text.tessellation.visualization.geologs {
  alignment-baseline: after-edge;
  text-anchor: end;
  fill: white;
  fill-opacity: 0.8;
  &:hover {
    fill-opacity: 1;
  }
}

.variables text.tessellation.visualization.geologs {
  fill: black;
}

text.tessellation.visualization.y-value,
text.tessellation.visualization.x-value {
  fill: black;
}

text.tessellation.visualization.y-value {
  // svg-specific property; vertical align for text
  alignment-baseline: central;
  // allows y-ticks to be right-aligned
  text-anchor: end;
}

text.tessellation.visualization.x-value,
text.tessellation.visualization.x-scale,
text.tessellation.visualization.y-scale {
  // svg-specific property; vertical align for text
  alignment-baseline: before-edge;
  text-anchor: middle;
}
