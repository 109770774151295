// Variables
$gs-primary: #122649; // Blue
$gs-secondary: #f0b41c; // Yellow
$default-background: #f5f5f5; // Grey
$darken-default-background: darken($default-background, 40%); // Grey
// https://mui.com/material-ui/customization/palette/
$mui-success-main: #2e7d32; // Green
$mui-success-alert-bg: #edf7ed; // pale success green
$app-box-bg: white;
$app-box-padding: 24px;
$app-box-padding-mobile: 16px;

$mui_error_light: #e57373;
$mui_error_dark: #d32f2f;
$mui_warning_light: #ffb74d;
$mui_warning_dark: #f57c00;
$mui_info_light: #4fc3f7;
$mui_info_dark: #0288d1;
$mui_success_light: #81c784;
$mui_success_dark: #388e3c;

$break-point-tablet-portrait: 600px;
$break-point-tablet-landscape: 900px;

// code-splitting
@import './print.scss';
@import './tessellation-vis.scss';
@import './changelog.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // from amplify package we removed:
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  min-height: 100vh;
}

// from amplify package that we removed
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-content {
  background-color: $default-background;
  min-height: 100vh;
  padding: 15px;
}

.App-box,
.App-panel-horizontal {
  background-color: $app-box-bg;
  padding: $app-box-padding;
  height: calc(100vh - #{$app-box-padding} * 4);
  overflow-x: auto;
  overflow-y: auto;
  white-space: normal;
  transition: border 300ms ease-out;
  position: relative;
  &.active {
    border: 1px $gs-primary solid;
    padding: $app-box-padding - 1;
  }

  &.App-box-horizontal {
    height: inherit;
  }

  h2 {
    margin-top: 0px;
  }
  * ~ h2 {
    // h2 not at the top of .app-box
    margin-top: 16px;
  }

  .bottom-button {
    margin-top: $app-box-padding;
  }

  .drop-zone {
    background-color: $default-background;
    border-radius: 3px;
    border: 2px $gs-primary solid;
    text-align: center;
    padding: 30px;
    &.active {
      border: 2px $gs-primary dotted;
      background-color: darken($default-background, 10%);
    }
    p,
    small {
      // background-color: #f0b41c;
      white-space: normal;
      overflow-wrap: break-word;
    }
  }
  .drop-zone.success {
    background-color: $mui-success-alert-bg;
    border-color: $mui-success-main;
    &.active {
      border-color: $mui-success-main;
      background-color: darken($mui-success-alert-bg, 10%);
    }
    .MuiSvgIcon-colorPrimary {
      color: $mui-success-main;
    }
  }
  .custom-label {
    font-weight: bold;
    min-width: 100px;
    margin-right: 16px;
  }
  .custom-link {
    text-decoration: none;
    color: $gs-primary;
    &:hover {
      color: darken($gs-primary, 20%);
    }
    &:visited,
    &:active {
      color: $gs-primary;
    }
  }
}

.MuiMenuItem-root span.menu-item-note {
  font-variant: small-caps;
  padding: 0px 5px;
}

button.MuiIconButton-root.transparent-white-bg {
  background-color: rgba(255, 255, 255, 0.5);
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.App-box.horizontal {
  border-bottom: 1px solid $gs-primary;
  padding: 0px;
}

.App-panel-horizontal {
  border-bottom: 1px solid $gs-primary;
  min-height: unset;
}

.jobs-table {
  th {
    background-color: #{$gs-primary}cc;
    color: white;
  }
}

.MuiDialog-container .file-explorer-tree {
  width: 100%;
  min-height: 600px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

/**
 * Geochem plot menu items
 */
ul.MuiMenu-list {
  // list items regardless of category
  li.geochem-plot-option,
  // list items recommended regardless of category
  li.geochem-plot-option.recommended-plot,
  // list items in a category
  li.geochem-plot-option.all,
  li.geochem-plot-option.lithology,
  li.geochem-plot-option.alteration,
  li.geochem-plot-option.petrogenesis,
  // list items in a category and recommended
  li.geochem-plot-option.all.recommended-plot,
  li.geochem-plot-option.lithology.recommended-plot,
  li.geochem-plot-option.alteration.recommended-plot,
  li.geochem-plot-option.petrogenesis.recommended-plot {
    span.callout-recommended,
    span.title,
    span.reference,
    span.subtitle,
    span.untitled {
      padding-right: 3px;
    }
  }
  li.geochem-plot-option.recommended-plot {
    font-weight: bold;
    color: $gs-primary;
  }
  li.geochem-plot-option span.subtitle {
    font-style: italic;
  }
}

nav.breadcrumb {
  margin-bottom: 10px;
  line-height: 27px;
  li {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    // margin-bottom: 5px;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    svg.MuiChip-icon {
      margin-right: 6px;
    }
    span:empty {
      cursor: pointer;
      display: none;
    }
  }
}

.sidebar {
  transition: width 0.2s ease;
  width: 0;
}

.sidebar.open {
  width: 250px;
}

/**
 * Geochem plot category radio buttons
 */
div.MuiGrid-item.plot-category-container {
  // the label for the group of buttons
  label.plot-category-label {
    border-color: none; //placeholder, unnecessary
  }
  // the group of radio buttons
  div.MuiFormGroup-root[role='radiogroup'].plot-option-categories {
    // all radio buttons regardless of category
    label,
    // the selected radio button regardless of category
    label.selected,
    // radio buttons of a category
    label.lithology,
    label.alteration,
    label.petrogenesis,
    label.all,
    // selected radio button of a category
    label.lithology.selected,
    label.alteration.selected,
    label.petrogenesis.selected,
    label.all.selected {
      // the label of the radio button, isolated from the actual radio button
      span.MuiFormControlLabel-label {
        border-color: none; // placeholder, unnecessary
      }
    }
  }
}

/**
 * Comparison plot checkboxes
 */
div.MuiGrid-item.comparison-plot-check-container {
  // the label for the group of checkboxes
  label.comparison-plot-check-label {
    border-color: none; //placeholder, unnecessary
  }
  // the group of checkboxes
  div.MuiFormGroup-root {
    flex-direction: row;
    // all checkboxes regardless of plot name
    label,
    // the selected checkbox regardless of plot name
    label.selected,
    // check boxes for each plot name
    label.sankey,
    label.stacked,
    label.crosstab,
    label.sunburst,
    // selected check boxes for each plot name
    label.sankey.selected,
    label.stacked.selected,
    label.crosstab.selected,
    label.sunburst.selected {
      // the label of the checkbox, isolated from the actual checkbox
      span.MuiFormControlLabel-label {
        border-color: none; // placeholder, unnecessary
      }
    }
  }
}

/*
 * Geochem plot details of selected plot
 */
div.MuiGrid-item.selected-plot-details {
  // the heading will be the title or reference if title is missing
  h3.selected-plot-heading.title,
  h3.selected-plot-heading.reference {
    border-color: none; // placeholder, unnecessary
  }
  // there will be a reference line if title is included
  p.reference,
  // the plot description
  p.description {
    border-color: none; // placeholder, unnecessary
  }
  // there will only be one .reference, either the heading or the paragraph
  .reference {
    border-color: none; // placeholder, unnecessary
  }
}

tr.recommended-signal {
  background-color: darken($default-background, 10%);
}

.jobs-card {
  position: relative;
  padding: 10px;
  border: 1px solid darken($default-background, 10%);
  div.jobs-title {
    position: relative;
    font-size: 90%;
    margin: 0;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .job-creator {
      font-weight: lighter;
      margin-left: 5px;
      font-size: 90%;
    }
  }
  .dateTime {
    margin-bottom: 5px;
    color: darken($default-background, 40%);
    small {
      display: inline-block;
      vertical-align: middle;
      padding-right: 8px;
    }
  }
  .jobAction {
    position: absolute;
    right: 10px;
    bottom: 10px;
    button,
    a {
      margin-top: 5px;
    }
  }
}

.h2Select div div {
  min-height: inherit;
  font-size: 1.5em;
  font-weight: bold;
  color: lighten(#122649, 20%);
  margin-left: 5px;
}

pre.api-response,
div.api-response {
  white-space: pre-wrap;
  overflow: auto;
  max-height: 300px;
  padding: 5px;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

details.additional-controls {
  cursor: grab;
  padding: 10px 0;
  margin-bottom: 10px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  cursor: row-resize;
  &[open] {
    cursor: inherit;
    border-top-color: darken($default-background, 10%);
    border-bottom-color: darken($default-background, 10%);
    & summary ~ * {
      animation: open 0.5s ease-in-out;
    }
  }
}

h2 ~ .sub-title,
div.h3 ~ .sub-title {
  color: $darken-default-background;
  margin-top: -20px;
  span {
    margin-right: 20px;
    font-size: 90%;
  }
  border-bottom: 1px solid $darken-default-background;
}

.Dash-box {
  padding: 0px !important;
  border-radius: 3px;
  h3 {
    margin-top: 0;
    padding: 5px;
    background-color: $default-background;
  }
}

.projects-card {
  border: 1px solid $gs-primary;
  .details {
    & > div {
      padding: 10px;
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      width: 50px;
      text-align: center;
      & > * {
        vertical-align: middle;
      }
    }
    .content {
      width: calc(100% - 50px);
      div {
        display: block;
      }
      .type {
        font-size: 80%;
        color: grey;
      }
      .title {
        font-weight: bold;
        font-size: 110%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .description {
        // line-height: 1em;
        // height: 2.1em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .go-to-action {
    display: block;
    background-color: $gs-primary;
    color: $default-background;
    text-align: center;
    text-decoration: none;
    padding: 10px;
  }
}

// input elements disabled for non-admin users
div.MuiFormControl-root.admin-editable, div.MuiFormControl-root.editable {
  &.title {
    div.MuiInputBase-root {
      // make text look like h3
      font-weight: bold;
    }
    &.h3 {
      div.MuiInputBase-root {
        // to make text look like h2
        font-size: x-large;
      }
    }
  }
  div.MuiInputBase-root {
    // override text color
    color: black;
    &.Mui-disabled:before {
      // when disabled, remove border
      border-style: none;
    }
    input,
    textarea {
      // override text color
      color: black;
      -webkit-text-fill-color: black;
    }
  }
}

.radio-group-save-logs {
  background-color: $default-background;
  border-radius: 4px;
  display: block !important;
  overflow: auto;
  max-height: 300px;
  padding: 4px;
}

.dialog-alert-details {
  pre {
    background-color: $default-background;
    padding: 1rem;
  }

  &.info div.MuiDialog-paper {
    pre {
      color: darken($mui_info_dark, 25%);
      background-color: lighten($mui_info_light, 25%);
    }
  }

  &.error div.MuiDialog-paper {
    pre {
      color: darken($mui_error_dark, 25%);
      background-color: lighten($mui_error_light, 25%);
    }
  }

  &.warning div.MuiDialog-paper {
    pre {
      color: darken($mui_warning_dark, 25%);
      background-color: lighten($mui_warning_light, 25%);
    }
  }

  &.success div.MuiDialog-paper {
    pre {
      color: darken($mui_success_dark, 25%);
      background-color: lighten($mui_success_light, 25%);
    }
  }
}

table.table-litholens-project-details {
  margin-bottom: 16px;
}

.table-litholens-project-details td {
  border-bottom: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  a {
    text-decoration: none;
    color: $gs-primary;
  }
  a:hover {
    text-decoration: none;
    color: darken($gs-primary, 25%);
  }
  a:active {
    text-decoration: none;
    color: darken($gs-primary, 25%);
  }
  a:visited {
    text-decoration: none;
    color: $gs-primary;
  }
}

.table-litholens-project-details td.label {
  font-weight: bold;
  padding-right: 24px;
}

.job-progress-container {
  position: relative;
  display: block;
  width: 100%;
}

.job-progress-status-container,
.job-progress-current-step-container {
  width: 50%;
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
}

.job-progress-status-container span,
.job-progress-current-step-container span {
  font-size: 0.9em;
}

.job-progress-status-container {
  justify-content: left;
}

.job-progress-current-step-container {
  justify-content: right;
}

.job-progess-list-item span,
.job-progess-list-item {
  font-size: 0.9em;
}

.job-progress-line {
  width: 100%;
  height: 0.5em !important;
  border-radius: 5px;
  display: block;
  margin-bottom: 0.25em;
}

.job-progress-current-step-label span,
.job-progress-status-label span {
  font-size: 0.9em;
}

.job-progress-icon {
  font-size: 1em !important;
  margin-right: 5px;
}

@media (max-width: $break-point-tablet-portrait) {
  // 600 px
  .App-box {
    padding: $app-box-padding-mobile;
  }
  .splom-hist-plot {
    min-height: 400px;
  }
}
@media (max-width: $break-point-tablet-landscape) {
  // 900 px
  .App-box {
    min-height: inherit;
    height: inherit;
    .bottom-button {
      position: inherit;
      bottom: auto;
      margin-top: 16px;
    }
  }
}

[data-amplify-authenticator] {
  display: none;
  [data-amplify-container] {
    [data-amplify-header] {
      text-align: center;
      margin-top: 100px;
    }
    [data-amplify-footer] {
      text-align: center;
      font-size: 80%;
    }
  }
}

// Table License Message
div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;'] {
  display: none !important;
  // background-color: #122649;
}
