#change-log-summary {
  a :visited,
  a :active {
    color: initial;
  }
  a :hover {
    color: $gs-primary;
  }
  a {
    display: block;
    text-decoration: none;
    cursor: pointer;
  }
  a.active {
    font-weight: bold;
    cursor: auto;
  }
}

#change-log-entries {
  overflow-y:scroll;
  max-height:80%;
  article.changelog-entry {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $darken-default-background;
    h1.title {
      font-size: 1.8em;
      margin-bottom: 10px;
    }
    .date {
      margin-top: 0px;
    }
    img {
      margin: 10px auto;
      display: block;
      max-width: 100%;
    }
    em,
    strong {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    a,
    a :visited,
    a :active,
    a :hover {
      color: $gs-primary;
      display: block;
      cursor: pointer;
    }
  }
  article.changelog-entry:last-of-type {
    border-bottom: unset
  }
  article.changelog-entry:first-of-type {
    padding-top: 0px;
  }
}