/**
 *  styles to make page printable
 */
$print-chart-height: 11cm;

// hide things meant only for print
*[data-only-print='true'] {
  display: none;
}

@media print {
  @page {
    margin: 1cm;
  }

  * {
    max-width: 100%;
  }

  *[data-only-print='true'] {
    display: block;
  }

  // Hide sidebar
  nav[data-testid='sidebar-component'] {
    display: none;
  }

  // hide scrollbar
  div.App-box {
    overflow:visible;
  }
  // remove background / make it transparent
  div.MuiContainer-root.App-content,
  div.App-box {
    background-color: rgba(255, 255, 255, 0);
  }

  // hide anything explicitly excluded in the JSX
  *[data-print='false'] {
    display: none !important;
  }

  // hide border of input fields
  .MuiInputBase-formControl {
    visibility: hidden;
  }

  // hide form helper text
  .MuiFormHelperText-root {
    display: none;
  }

  // hide background of pills
  div.MuiChip-root.MuiChip-filled {
    background: none;
  }

  // layout of form elements
  div.MuiGrid-root.MuiGrid-item {
    display: inline-block;
    min-width: fit-content;
    width: 50%;
    max-width: 100%;
    margin-top: 0px;
    padding-top: 5px;
  }

  // remove the negative margin on the form container
  .MuiGrid-root.MuiGrid-container {
    display: inline-block;
    margin-top: 0px;
  }

  // Normalize styled labels
  label.MuiInputLabel-root.MuiInputLabel-formControl {
    position: relative;
    transform: none;
  }

  // explicitly show the div holding the input value
  div.MuiInputBase-input,
  input.MuiInputBase-input {
    display: inline-block;
    position: relative;
    padding: 0;
    visibility: visible;
  }

  // remove input field box from document flow
  fieldset.MuiOutlinedInput-notchedOutline {
    display: none;
  }

  // hide the select arrow icon
  svg.MuiSelect-icon {
    display: none;
  }

  // hide the (empty) native input
  input.MuiSelect-nativeInput {
    display: none;
  }

  // print-friendly checkbox
  .MuiCheckbox-root svg.MuiSvgIcon-root {
    fill: none;
    stroke: black;
    stroke-opacity: 1;
    opacity: 1;
  }

  // hide "required" asterisk
  span.MuiInputLabel-asterisk {
    display: none;
  }

  // hide detail element
  figure.tessellation details {
    display: none;
  }
}

@media print {
  svg.tessellation.visualization {
    height: $print-chart-height;
    min-height: $print-chart-height;
    max-height: $print-chart-height;
    margin: 0cm auto;
  }
  svg.tessellation.domain-classification.visualization {
    // override max width from svg.tessellation.visualization
    max-width: 100%;
    width: $print-chart-height;
  }
  .tessellation-mosaic {
    h4 {
      max-width: 100% !important;
      min-width: inherit !important;
      width: 100% !important;
      span span, svg {
        display: none;
      }
    }
    .tessellation-mosaic-small {
      min-width: inherit !important;
      width: 16% !important;
      max-width: 16% !important;
      padding: 0 !important;
    }
    .tessellation-mosaic-big {
      min-width: inherit !important;
      width: 40% !important;
      max-width: 40% !important;
      padding: 0 !important;
    }
  }
}
